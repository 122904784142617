* {
  margin: 0;
  padding: 0;
  line-height: 1;
}

html,
body {
  height: 100%;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(./bg.jpg);
  font-family: Georgia, serif;
  flex-direction: column;
  color: #fff;
  text-shadow: 1px 1px 1px black;
}

.logo {
  background-color: #413028;
  border-radius: 50%;
  box-shadow: 1px 1px 4px 1px #000;
  padding: 1rem;
}

main {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

footer {
  padding: 1rem;
}
