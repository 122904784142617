* {
  margin: 0;
  padding: 0;
  line-height: 1;
}

html, body {
  height: 100%;
}

body {
  color: #fff;
  text-shadow: 1px 1px 1px #000;
  background-image: url("bg.53585c01.jpg");
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Georgia, serif;
  display: flex;
}

.logo {
  background-color: #413028;
  border-radius: 50%;
  padding: 1rem;
  box-shadow: 1px 1px 4px 1px #000;
}

main {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

footer {
  padding: 1rem;
}

/*# sourceMappingURL=index.f8bc4dab.css.map */
